'use strict';
angular
    .module('travelsearch.config', [])
    .config(["tmhDynamicLocaleProvider", function (tmhDynamicLocaleProvider) {
        tmhDynamicLocaleProvider.localeLocationPattern('https://cdnjs.cloudflare.com/ajax/libs/angular-i18n/1.7.8/angular-locale_{{locale}}.js');
    }])
    .run(["$rootScope", "$cookies", "$filter", "$timeout", "tmhDynamicLocale", function ($rootScope, $cookies, $filter, $timeout, tmhDynamicLocale) {
    
    var now = new Date();
    $rootScope.numChildren = 10;
    $rootScope.search = {};
    $rootScope.filters = {};

    $rootScope.reauthorized = false;

    $rootScope.historyHotels = [];

    $rootScope.language = angular.element("html").attr("lang");

    $rootScope.shortDateFormat = "yy-mm-dd";
    $rootScope.longDateFormat = "yyyy-MM-dd";

    if ($rootScope.language == "da") {
        $rootScope.shortDateFormat = "dd-mm-yy";
        $rootScope.longDateFormat = "dd-MM-yyyy";
    }

    var datePickerLanguage = $rootScope.language;
    if ($rootScope.language == "en") {
        datePickerLanguage = "en-GB";
    }
    if ($rootScope.language == "nb") {
        datePickerLanguage = "no";
    }

    tmhDynamicLocale.set($rootScope.language);

    var datePickerRegional = $.datepicker.regional[datePickerLanguage];
    datePickerRegional.dateFormat = $rootScope.shortDateFormat;

    $.datepicker.setDefaults(
        datePickerRegional
    );

    $rootScope.culture = "en-US";
    switch ($rootScope.language) {
        case "da":
            $rootScope.culture = "da-DK";
            break;
        case "sv":
            $rootScope.culture = "sv-SE";
            break;
        case "nb":
            $rootScope.culture = "nb-NO";
            break;
        case "de":
            $rootScope.culture = "de-DE";
            break;
        default:
            $rootScope.culture = "en-US";
            break;
    }

    $rootScope.getParameterByName = function (name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
		results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    };

    $rootScope.addDays = function (date, days) {
        var result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    };

    $rootScope.getDateDiff = function (startDate, endDate) {
        var startDate = new Date(startDate);
        var endDate = new Date(endDate);
        var diff = endDate.getTime() - startDate.getTime();
        var days = Math.floor(diff / (60 * 60 * 24 * 1000));
        var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
        var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
        var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
        return { day: days, hour: hours, minute: minutes, second: seconds };
    };

    if (!angular.isUndefined($cookies.getObject("search"))) {
        $rootScope.search = $cookies.getObject("search");
    }

    if ($rootScope.getParameterByName("saeson")) {
        $rootScope.search.season = $rootScope.getParameterByName("saeson");
    }
    if ($rootScope.getParameterByName("transport")) {
        $rootScope.search.transport = $rootScope.getParameterByName("transport");
    }
    if ($rootScope.getParameterByName("dage")) {
        $rootScope.search.dage = parseInt($rootScope.getParameterByName("dage"));
    }
    if ($rootScope.getParameterByName("dato")) {
        $rootScope.search.dato = $rootScope.getParameterByName("dato");
    }
    if ($rootScope.getParameterByName("voksne")) {
        $rootScope.search.voksne = parseInt($rootScope.getParameterByName("voksne"));
    }
    if ($rootScope.getParameterByName("born")) {
        $rootScope.search.born = parseInt($rootScope.getParameterByName("born"));
    }

    if ($rootScope.getParameterByName("alder") && $rootScope.search.born && $rootScope.search.born > 0) {
        var alderArray = $rootScope.getParameterByName("alder").split(',');
        var alderObj = {};
        for (i = 0; i < $rootScope.search.born; i++) {
            alderObj[i] = parseInt(alderArray[i]);
        }

        $rootScope.search.alder = alderObj;
    }

    $cookies.putObject("search", $rootScope.search, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });

    if ($("body").hasClass("hotellerogpriser")) {

        if (!angular.isUndefined($cookies.getObject("filters"))) {
            $rootScope.filters = $cookies.getObject("filters");
        }

        if (angular.isUndefined($rootScope.filters.sorter)) {
            $rootScope.filters.sorter = "0";
        }

        // Own Hotels
        if ($rootScope.getParameterByName("egnehoteller")) {
            $rootScope.filters.ownhotels = $rootScope.getParameterByName("egnehoteller") == "true";
        }

        // Standards
        var standard2 = $rootScope.getParameterByName("standard2"),
            standard3 = $rootScope.getParameterByName("standard3"),
            standard4 = $rootScope.getParameterByName("standard4"),
            standard5 = $rootScope.getParameterByName("standard5");
        
        if (standard2 || standard3 || standard4 || standard5)
        {
            $rootScope.filters.standards = $rootScope.filters.standards || {};

            if (standard2) {
                $rootScope.filters.standards["2"] = standard2 == "true";
            }
            if (standard3) {
                $rootScope.filters.standards["3"] = standard3 == "true";
            }
            if (standard4) {
                $rootScope.filters.standards["4"] = standard4 == "true";
            }
            if (standard5) {
                $rootScope.filters.standards["5"] = standard5 == "true";
            }
        }

        // Boards
        var noboard = $rootScope.getParameterByName("nopension"),
            quarterboard = $rootScope.getParameterByName("kvartpension"),
            halfboard = $rootScope.getParameterByName("halvpension"),
            fullboard = $rootScope.getParameterByName("helpension"),
            addon = $rootScope.getParameterByName("tilkobes"),
            allinclusive = $rootScope.getParameterByName("allinclusive");

        if (noboard || quarterboard || halfboard || fullboard || addon || allinclusive)
        {
            $rootScope.filters.boards = $rootScope.filters.boards || {};

            if (noboard) {
                $rootScope.filters.boards.none = noboard == "true";
            }
            if (quarterboard) {
                $rootScope.filters.boards.quarter = quarterboard == "true";
            }
            if (halfboard) {
                $rootScope.filters.boards.half = halfboard == "true";
            }
            if (fullboard) {
                $rootScope.filters.boards.full = fullboard == "true";
            }
            if (addon) {
                $rootScope.filters.boards.addon = addonboard == "true";
            }
            if (allinclusive) {
                $rootScope.filters.boards.allinclusive = allinclusive == "true";
            }
        }

        // Distances
        var closeToLifts = $rootScope.getParameterByName("tatlift"),
            closeToCenter = $rootScope.getParameterByName("tatcentrum");

        if (closeToLifts || closeToCenter)
        {
            $rootScope.filters.distances = $rootScope.filters.distances || {};

            if (closeToLifts) {
                $rootScope.filters.distances.lifts = closeToLifts == "true";
            }
            if (closeToCenter) {
                $rootScope.filters.distances.center = closeToCenter == "true";
            }
        }

        // Facilities
        var internet = $rootScope.getParameterByName("internet"),
            tv = $rootScope.getParameterByName("tv"),
            bar = $rootScope.getParameterByName("bar"),
            parking = $rootScope.getParameterByName("parkering"),
            playroom = $rootScope.getParameterByName("legerum"),
            kidactivities = $rootScope.getParameterByName("borneaktiviteter"),
            pool = $rootScope.getParameterByName("swimmingpool"),
            sauna = $rootScope.getParameterByName("sauna"),
            hottub = $rootScope.getParameterByName("boblebad"),
            tanningbed = $rootScope.getParameterByName("solarie"),
            wellness = $rootScope.getParameterByName("wellness");

        if (internet || tv || bar || parking || playroom || kidactivities || pool || sauna || hottub || tanningbed || wellness)
        {
            $rootScope.filters.facilities = $rootScope.filters.facilities || {};

            if (internet) {
                $rootScope.filters.facilities.internet = internet == "true";
            }
            if (tv) {
                $rootScope.filters.facilities.tv = tv == "true";
            }
            if (bar) {
                $rootScope.filters.facilities.bar = bar == "true";
            }
            if (parking) {
                $rootScope.filters.facilities.parking = parking == "true";
            }
            if (playroom) {
                $rootScope.filters.facilities.playroom = playroom == "true";
            }
            if (kidactivities) {
                $rootScope.filters.facilities.kidactivities = kidactivities == "true";
            }
            if (pool) {
                $rootScope.filters.facilities.pool = pool == "true";
            }
            if (sauna) {
                $rootScope.filters.facilities.sauna = sauna == "true";
            }
            if (hottub) {
                $rootScope.filters.facilities.hottub = hottub == "true";
            }
            if (tanningbed) {
                $rootScope.filters.facilities.tanningbed = tanningbed == "true";
            }
            if (wellness) {
                $rootScope.filters.facilities.wellness = wellness == "true";
            }
        }

        $cookies.putObject("filters", $rootScope.filters, { 'path': window.location.pathname, 'expires': $rootScope.addDays(now, 7) });
    }

    if (!angular.isUndefined($cookies.get("salg"))) {
        $rootScope.numChildren = 200;
    }

    $rootScope.addZero = function (no) {
        if (no < 10) {
            return "0" + no;
        } else {
            return no;
        }
    };

    $rootScope.isGroupTravel = function (adults, children) {
        return parseInt(adults) + parseInt(children) > 10;
    };

    $rootScope.getFilterSorting = function (sort) {

        var sorting = "";

        switch (sort) {
            case "0":
                sorting = "+sortorder";
                break;
            case "1":
                sorting = "+price";
                break;
            case "2":
                sorting = "-price";
                break;
            case "3":
                sorting = "+discountprice";
                break;
            case "4":
                sorting = "+departuredate";
                break;
            case "5":
                sorting = "+distances.lifts.value";
                break;
            default:
                sorting = "+sortorder";
                break;
        }

        return sorting;
    };

    $rootScope.getLastMinuteFiltersFromQueryString = function() {

        var filters = {};

        if ($rootScope.getParameterByName("pension")) {
            var boardArr = $rootScope.getParameterByName("pension").split(",");

            filters.boards = boardArr.reduce(function (obj, value) {
                obj[value] = true;
                return obj;
            }, {});
        }

        if ($rootScope.getParameterByName("dato")) {
            var dateArr = $rootScope.getParameterByName("dato").split(",");

            filters.dates = dateArr.reduce(function (obj, value) {
                obj[value] = true;
                return obj;
            }, {});
        }

        if ($rootScope.getParameterByName("transport")) {
            var transportArr = $rootScope.getParameterByName("transport").split(",");

            filters.transports = transportArr.reduce(function (obj, value) {
                obj[value] = true;
                return obj;
            }, {});
        }

        if ($rootScope.getParameterByName("destination")) {
            var destinationArr = $rootScope.getParameterByName("destination").split(",");

            filters.destinations = destinationArr.reduce(function (obj, value) {
                obj[value] = true;
                return obj;
            }, {});
        }

        if (angular.isUndefined(filters.sorter) || filters.sorter === null) {
            filters.sorter = "4";
        }

        return filters;
    };

    $rootScope.parseDate = function (input, format) {
        format = format || 'yyyy-mm-dd'; // default format
        var parts = input.match(/(\d+)/g),
            i = 0, fmt = {};
        // extract date-part indexes from the format
        format.replace(/(yyyy|dd|mm)/g, function (part) { fmt[part] = i++; });

        return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']]);
    };

    $rootScope.getClosestDate = function (dateList, currentDate) {
        var selectedDate = $rootScope.parseDate(currentDate);
        var lowestDiff = 1000;
        var returnDate = dateList[0].date;

        angular.forEach(dateList, function (obj, key) {
            var singleDate = $rootScope.parseDate(obj.date);
            var timeDiff = Math.abs(selectedDate - singleDate);

            if (lowestDiff > Math.ceil(timeDiff / (1000 * 3600 * 24))) {
                lowestDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));
                returnDate = obj.date;
            }

        });
        return returnDate;
    };

    $rootScope.setDatePicker = function (dateList, inputId, displayDays) {
        var firstDate = $rootScope.parseDate(dateList[0].date);
        var lastDate = $rootScope.parseDate(dateList[dateList.length - 1].date);

        var monthSpan = 3;
        var setMinDate = new Date(firstDate);
        setMinDate.setMonth(setMinDate.getMonth() - monthSpan);
        var setMaxDate = new Date(lastDate);
        setMaxDate.setMonth(setMaxDate.getMonth() + monthSpan);

        // Set data attribute, so we can use this value in hightligh (init and mouseover).
        if (displayDays) {
            $("#" + inputId).attr("data-displaydays", displayDays);
        }

        $rootScope.highlightTraveldays = function (objStartDay, inputId, displayDays) {
            $(".ui-datepicker-group td").removeClass("traveldays");
            var intDaysCounter = 0;
            var intWeekDay = parseInt($("#ui-datepicker-div td").index(objStartDay) % 8);

            var intTravelDays = displayDays || parseInt($("#dato").attr("data-displaydays"));

            if (!angular.isUndefined(inputId)) {
                inputFieldID = inputId;
            }

            if (inputFieldID == "hotel-dato") {
                intTravelDays = displayDays || parseInt($("#hotel-dato").attr("data-displaydays"));
            }

            objStartDay.addClass("traveldays");
            for (var intIndex1 = intWeekDay; intIndex1 < (intWeekDay + intTravelDays - 1); intIndex1++) {
                if (!objStartDay.siblings().eq(intIndex1).hasClass("ui-datepicker-other-month") && objStartDay.siblings().eq(intIndex1).length != 0) {
                    objStartDay.siblings().eq(intIndex1).addClass("traveldays");
                    intDaysCounter++;
                }
            }
            if (intWeekDay + intTravelDays > 7) {
                // Der skal markeres dage i ugen efter startdatoen (uge 2)
                for (var intIndex2 = 1; intIndex2 < intWeekDay + intTravelDays - 7; intIndex2++) {
                    if (!objStartDay.parent().next().children().eq(intIndex2).hasClass("ui-datepicker-other-month") && objStartDay.parent().next().children().eq(intIndex2).length != 0) {
                        objStartDay.parent().next().children().eq(intIndex2).addClass("traveldays");
                        intDaysCounter++;
                    }
                }
            }
            if (intWeekDay + intTravelDays > 14) {
                //Der skal markeres dage i endnu en uge (uge 3) 
                for (var intIndex3 = 1; intIndex3 < intWeekDay + intTravelDays - 14; intIndex3++) {
                    if (!objStartDay.parent().nextAll().eq(1).children().eq(intIndex3).hasClass("ui-datepicker-other-month") && objStartDay.parent().nextAll().eq(1).children().eq(intIndex3).length != 0) {
                        objStartDay.parent().nextAll().eq(1).children().eq(intIndex3).addClass("traveldays");
                        intDaysCounter++;
                    }
                }
            }
            if (intDaysCounter + 1 != intTravelDays) {
                // Der er flere dage end markeret - fordi nogle dage ligger i næste måned
                if (objStartDay.parents(".ui-datepicker-group-first").length != 0) {
                    // Start-måneden er den første af de to viste måneder.
                    // Derfor markeres det antal dage, der mangler:
                    $(".ui-datepicker-group-last td").each(function (index) {
                        if (index < 20 && parseInt($(this).text()) < intTravelDays - intDaysCounter && ($(this).hasClass("bad_date") || $(this).hasClass("good_date"))) {
                            $(this).addClass("traveldays");
                        }
                    });
                } else {
                    $(".ui-datepicker-group-last td").each(function (index) {
                        if (index > 20 && parseInt($(this).text()) < intTravelDays - intDaysCounter && $(this).hasClass("ui-datepicker-other-month")) {
                            $(this).addClass("traveldays");
                        }
                    });
                }
            }
        };

        $('body').on('mouseover', 'td.good_date', function (event) {
            $rootScope.highlightTraveldays($(this), inputId);
        });

        $('body').on('mouseleave', 'td.good_date', function (event) {
            $(".ui-datepicker-group td").removeClass("traveldays");
            if ($(".ui-datepicker-current-day").eq(0).length > 0 && $(".ui-datepicker-current-day").eq(0).hasClass("ui-datepicker-other-month") != true) {
                $rootScope.highlightTraveldays($(".ui-datepicker-current-day").eq(0), inputId);
            }
        });

        var monthNumber = [1, 2];
        if ($(window).width() < 991) {
            monthNumber = null;
        }

        var hostname = window.location.hostname.toLowerCase();

        $rootScope.dateOptions = {
            minDate: $filter('date')(setMinDate, $rootScope.longDateFormat),
            maxDate: $filter('date')(setMaxDate, $rootScope.longDateFormat),
            defaultDate: $filter('date')(firstDate, $rootScope.longDateFormat),
            firstDay: 1,
            showWeek: true,
            showOtherMonths: false,
            selectOtherMonths: false,
            numberOfMonths: monthNumber,
            showButtonPanel: false,
            beforeShowDay: function (date) {
                var date_str = $filter('date')(date, "yyyy-MM-dd");
                var hasDate = $filter("contains")(dateList, 'date =="' + date_str + '"');                
                if (hasDate) {
                    return [true, "good_date", ""];
                } else {
                    return [false, "bad_date", ""];
                }
            },
            beforeShow: function (input, inst) {
                window.setTimeout(function () {
                    var objCurrent = $(inst.dpDiv).find("td.ui-datepicker-current-day").eq(0);

                    $rootScope.highlightTraveldays(objCurrent, $(input).attr("id"), displayDays);

                }, 200);
            }
        };
    };

    $rootScope.anyInvalidFields = function (form) {
        return form.$invalid;
    };

    $rootScope.initFavorites = function () {
        $rootScope.favoriteHotels = [];

        $('.hotel-list, .hotel-page').on("click", '.favorite, .favoritelabel', function (e) {

            // wait until cookie has been updated
            $timeout(function () {
                if (!angular.isUndefined($cookies.get("favorit"))) {
                    var favoriteHotels = $cookies.get("favorit").split("-").filter(Boolean);
                    $rootScope.favoriteHotels = favoriteHotels;

                    var favoriteTab = angular.element("#widget_tab_favorites");
                    favoriteTab.addClass("selected").siblings().removeClass("selected");
                    var heartElem = favoriteTab.find('[class*="fa-heart"]');
                    heartElem.addClass("faa-pulse faa-fast animated red");

                    var widgetTabsContent = angular.element(".widget-history-favorites .widget-tabs-content");
                    widgetTabsContent.find("li.widget-tabs-item-history").hide();
                    widgetTabsContent.find("li.widget-tabs-item-favorites").show();

                    $timeout(function () {
                        heartElem.removeClass("animated red");
                    }, 2000);
                }
            }, 0);
        });

        if (!angular.isUndefined($cookies.get("favorit"))) {
            var favHotels = $cookies.get("favorit").split("-").filter(Boolean);
            $rootScope.favoriteHotels = favHotels || [];
        }
    };

    $rootScope.initFavorites();

    function addLegends(inst) {

        var legends = $("<ul>", {
            class: "ui-datepicker-legends"
        });

        $("<li />", { class: "legend-departure", text: "Afrejsedag" }).appendTo(legends);
        $("<li />", { class: "legend-travel", text: "Rejsedage" }).appendTo(legends);

        var $datepicker = $(inst.dpDiv).closest(".ui-datepicker");
        $datepicker.find(".ui-datepicker-group").append(legends);
    }

    String.format = function () {
        var s = arguments[0];
        for (var i = 0; i < arguments.length - 1; i++) {
            var reg = new RegExp("\\{" + i + "\\}", "gm");
            s = s !== undefined ? s.replace(reg, arguments[i + 1]) : "";
        }
        return s;
    };

}]);